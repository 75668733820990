
import React, { useState } from 'react';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from 'axios'
import { until } from 'async';
function App() {

    const [nameTeacher,setNameTeacher] = useState("")
    const [phoneTeacher,setPhoneTeacher] = useState("")
    const [emailTeacher,setEmailTeacher] = useState("")
    const [schoolName, setSchoolName] = useState("")
    const [schoolPhone,setSchoolPhone] = useState("")
    const [schoolEmail, setSchoolEmail] = useState("")
    const [content, setContent] = useState("")
    const [unit,setUnit] = useState("")

    const handel_submit_form = (event)=>{
        event.preventDefault()
        let teachername = nameTeacher.trim()
        let teacherphone = phoneTeacher.trim()
        let teacheremail = emailTeacher.trim()
        let schoolname = schoolName.trim()
        let schoolphone = schoolPhone.trim()
        let schoolemail = schoolEmail.trim()
        let content_ = content.trim()
        let unit_ = unit.trim()
        if(teachername == "" || teacheremail == "" || teacherphone == "" || schoolemail == "" || schoolname == "" || schoolphone == "" || content_ ==""){
            NotificationManager.error('Xin hãy nhập đầy đủ thông tin')
            return
        }
        axios.post('https://create-coures-lti-online.megaschool.edu.vn/registers', {
            teacheremail: teacheremail,
            teachername: teachername,
            teacherphone: teacherphone,
            schoolname:schoolname,
            schoolemail:schoolemail,
            schoolphone:schoolphone,
            content:content_
        }).then(res=>{
            NotificationManager.success('Đăng ký thành công')
            setNameTeacher("")
            setEmailTeacher("")
            setPhoneTeacher("")
            setSchoolEmail("")
            setSchoolName("")
            setSchoolPhone("")
            setContent("")
        }).catch(err=>{
            NotificationManager.error('Đăng ký thất bại')
        })
    }
  return (
    <div>
     <header className="header" id="header">
            <nav className="navbar navbar-uts">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse-uts">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="/">
                            <img src="img/logo.svg" className="logo" alt=""/>
                        </a>
                        <ul className="nav navbar-nav navbar-profile visible-xs-block">
                            <li className="dropdown">
                                <a href="#" data-toggle="dropdown" className="dropdown-toggle">
                                    EN
                                    <b className="caret"></b>
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a href="#">
                                            <span>VN</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="collapse navbar-collapse" id="navbar-collapse-uts">
                        <ul className="nav navbar-nav navbar-right navbar-hotline hidden-xs">
                            <li className="hotline">
                                <a href="tel:1900 234 582">1900 234 582</a>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right navbar-lang hidden-xs">
                            <li>
                                <a className="vietnamese" href="#">
                                    VN
                                </a>
                            </li>
                            <li>
                                <a className="english" href="#">
                                    EN
                                </a>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right navbar-uts navscroll">
                            <li className="active">
                                <a href="/">Trang chủ</a>
                            </li>
                            <li><a href="#news">Tin tức</a></li>
                            <li><a href="#contact">Liên hệ</a></li>
                            <li className="login"><a href="https://online.megaschool.edu.vn">Đăng nhập</a></li>
                        </ul>
                        <div className="hidden-lg hidden-md hidden-sm" id="need-support">
                            <h3 className="need-support-title">Cần hỗ trợ?</h3>
                            <ul className="need-support">
                                <li>
                                    <a href="tel:1900 234 582">
                                        <i className="fa fa-phone-square"></i> 1900 234 582
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
        <div id="primary" className="content-area page page-homepage" data-page="page-homepage">
            <main role="main">
                <div className="hero_banner" style={{backgroundImage: "img/hero_banner.png"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-xs-12">
                                <div className="content wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.15s">
                                    <h2>Nền tảng dạy và học trực tuyến</h2>
                                    <p>MegaSchool là nền tảng dạy và học trực tuyến với đầy đủ các tính năng quản lý học liệu và trường lớp, đáp ứng tất cả các nhu cầu của nhà trường, giáo viên và học sinh.</p>
                                    <div className="action">
                                        <a className="btn btn-yellow" href="#contact">
                                            <span>Đăng ký ngay</span>
                                        </a>
                                        <a className="btn btn-yellow" href="https://online.megaschool.edu.vn">
                                            <span>TÌM HIỂU THÊM</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xs-12 hidden-xs">
                                <div className="image wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.15s">
                                    <img  src="img/rev_slider.png" className="img-responsive" alt="Nền tảng công nghệ toàn diện" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section why" id="section-why">
                    <div id="scene_about_big" className="scene scene_about_big">
                        <div data-depth="0.1" className="special_big"></div>
                    </div>
                    <div className="section-body">
                        <div className="container">
                            <div id="scene_about" className="scene scene_about">
                                <div data-depth="0.2" className="special"></div>
                                <div data-depth="0.24" className="special_1"></div>
                                <div data-depth="0.3" className="special_2"></div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-12">
                                    <div className="image wow fadeIn" data-wow-duration="2s" data-wow-delay="0.15s">
                                        <figure>
                                            <img src="img/bannerweb01.png" className="img-responsive" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-xs-12">
                                    <div className="content wow fadeIn" data-wow-duration="2s" data-wow-delay="0.15s">
                                        <h2>Về chúng tôi - Mega<span>School</span></h2>
                                        <div className="summary">
                                            <p>MegaSchool là sản phẩm của Khối Công nghệ giáo dục (EdTech) thuộc Tập đoàn Giáo dục EQuest, một trong những tổ chức giáo dục tư nhân lớn nhất Việt Nam với hơn 110.000 học viên theo học mỗi năm tại các đơn vị thành viên. Tháng 6 vừa qua, KKR, một trong những công ty đầu tư hàng đầu thế giới đã chính thức đầu tư vào EQuest với mục tiêu giúp EQuest mở rộng hoạt động và tiếp tục sứ mệnh mang đến cho học sinh, sinh viên Việt Nam cơ hội tiếp cận giáo dục chất lượng đẳng cấp thế giới với chi phí phù hợp.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section provider">
                    <div className="section-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-xs-12 is-content">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="main_title wow fadeInDown" data-wow-duration="2s" data-wow-delay="0.15s">
                                                <a target='_blank' href='https://moet.gov.vn/tintuc/Pages/tin-tong-hop.aspx?ItemID=7284'>
                                                <h2>
                                                    <span>Mega<strong>School</strong></span>
                                                    <span> là giải pháp toàn diện</span>
                                                </h2>
                                                <p>đáp ứng đủ 4 tiêu chí "Tổ chức dạy học trực tuyến" của Bộ Giáo Dục và Đào tạo
                                                </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 inner">
                                            <div className="provide wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.15s">
                                                <div className="provide-number-box-wrapper">
                                                    <div className="provide-number">
                                                        <span>01</span>
                                                    </div>
                                                </div>
                                                <div className="provide-text color1">
                                                    <h3>Dạy học trực tuyến</h3>
                                                    <p>MegaSchool cung cấp nền tảng đảm bảo việc học trực tuyến của học sinh, dạy trực tuyến của giáo viên, và khả năng tương tác trong quá trình dạy-học.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 inner ">
                                            <div className="provide wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.15s">
                                                <div className="provide-number-box-wrapper">
                                                    <div className="provide-number color2">
                                                        <span>02</span>
                                                    </div>
                                                </div>
                                                <div className="provide-text color2">
                                                    <h3>Ôn tập và khảo thí trực tuyến</h3>
                                                    <p>MegaSchool tích hợp nền tảng ôn tập và khảo thí trực tuyến thuận tiện trong suốt quá trình dạy-học. Công tác kiểm tra, khảo thí trực tuyến định kỳ với công nghệ chống gian lận, đạo văn đảm bảo công bằng, khách quan.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 inner">
                                            <div className="provide wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                                                <div className="provide-number-box-wrapper">
                                                    <div className="provide-number color3">
                                                        <span>03</span>
                                                    </div>
                                                </div>
                                                <div className="provide-text color3">
                                                    <h3>Học liệu trực tuyến</h3>
                                                    <p>MegaSchool hỗ trợ giáo viên xây dựng bài giảng số tương tác theo đúng quy định và thuận tiện kiểm định chuyên môn với công nghệ H5P.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 inner">
                                            <div className="provide wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.8s">
                                                <div className="provide-number-box-wrapper">
                                                    <div className="provide-number color4">
                                                        <span>04</span>
                                                    </div>
                                                </div>
                                                <div className="provide-text color4">
                                                    <h3>Quản lý và lưu trữ hồ sơ dạy học trực tuyến</h3>
                                                    <p>MegaSchool đảm bảo hồ sơ dạy học trực tuyến được bảo quản và lưu trữ với độ bảo mật cao bằng công nghệ điện toán đám mây.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 hidden-xs">
                                    <div className="image">
                                        <img src="img/4_tieu_chi.png" className="img-responsive" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section provider with_icon">
                    <div id="scene_provider" className="scene scene_about wow fadeIn">
                        <div data-depth="0.2" className="special"></div>
                        <div data-depth="0.24" className="special_1"></div>
                        <div data-depth="0.3" className="special_2"></div>
                        <div data-depth="0.3" className="special_big"></div>
                    </div>
                    <div className="section-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-xs-12 title_wraper">
                                    <div className="title wow fadeIn" data-wow-delay="1.2s">
                                        <h2>
                                            <span>Ưu điểm vượt trội của Mega<strong>School</strong></span>
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-xs-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-xs-12 inner"  >
                                            <div className="provide wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">
                                                <div className="provide-number-box-wrapper">
                                                    <div className="provide-number color5"></div>
                                                </div>
                                                <div className="provide-text color5">
                                                    <h3>Lớp học trực tuyến</h3>
                                                    <p>MegaSchool cung cấp giải pháp lớp học trực tuyến tích hợp Zoom/Google Meet/MS Team, có tốc độ <strong>đường truyền ổn định</strong> và miễn phí trong năm học 2021-2022.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 inner">
                                            <div className="provide wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.2s">
                                                <div className="provide-number-box-wrapper">
                                                    <div className="provide-number color6"></div>
                                                </div>
                                                <div className="provide-text color6">
                                                    <h3>Bài giảng số chuẩn</h3>
                                                    <p>Hệ thống bài giảng số có sẵn theo <strong>chuẩn sách giáo</strong> khoa với hình thức trình bày sinh động, thu hút, có tính tương tác cao với học sinh.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 inner">
                                            <div className="provide wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.3s">
                                                <div className="provide-number-box-wrapper">
                                                    <div className="provide-number color7"></div>
                                                </div>
                                                <div className="provide-text color7">
                                                    <h3>Hệ sinh thái giáo dục bổ trợ</h3>
                                                    <p>Nội dung học tập phong phú hơn với <strong>hệ sinh thái liên tục được mở rộng</strong>, tích hợp với các nền tảng ôn bài - khảo thí và giáo dục quốc tế có chọn lọc.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 inner">
                                            <div className="provide wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.4s">
                                                <div className="provide-number-box-wrapper">
                                                    <div className="provide-number color8"></div>
                                                </div>
                                                <div className="provide-text color8">
                                                    <h3>Giải pháp giáo dục ưu việt</h3>
                                                    <p>Khả năng vận dụng <strong>dữ liệu lớn</strong> để đưa ra các giải pháp và lộ trình học tập phong phú cho các nhóm đối tượng học sinh khác nhau.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section mobile">
                    <div className="section-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="content wow fadeInLeft">
                                        <h3>Ứng dụng di động</h3>
                                        <h4>Học tập mọi lúc-mọi nơi</h4>

                                        <div className="action">
                                            <a href="https://apps.apple.com/cm/app/megaschool-teacher/id1599478552" className="btn btn-blue">
                                                <span style={{fontSize:'14px'}}>Dành cho giáo viên IOS</span>
                                            </a>
                                            <a href="https://apps.apple.com/zw/app/megaschool-student/id1598088283" className="btn btn-blue">
                                                <span style={{fontSize:'14px'}}>Dành cho Học Sinh IOS</span>
                                            </a>
                                        </div>
                                        <br/>
                                        <div className="action">
                                            <a href="https://play.google.com/store/apps/details?id=com.online.megaschool.teacher" className="btn btn-blue">
                                                <span style={{fontSize:'14px'}}>Dành cho giáo viên Android</span>
                                            </a>
                                            <a href="https://play.google.com/store/apps/details?id=com.online.megaschool.student" className="btn btn-blue">
                                                <span style={{fontSize:'14px'}}>Dành cho Học Sinh Android</span>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="image wow fadeInRight">
                                        <img src="img/mobile.png" className="img-responsive" alt="Image"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section testimonials">
                    <header className="section-head">
                        <div className="container">
                            <div className="main_title wow fadeIn">
                                <h2>Đánh giá của chuyên gia</h2>
                            </div>
                        </div>
                    </header>
                    <div className="section-body wow fadeIn">
                        <div id="scene" className="scene scene_testimonials">
                            <div data-depth="0.4" className="special"></div>
                            <div data-depth="0.4" className="special_1"></div>
                            <div data-depth="0.4" className="special_2"></div>
                        </div>
                        <div className="container">
                            <div className="owl_testimonial owl-carousel owl-theme">

                                <div className="testimonial">
                                    <div className="content">
                                        <p>“Mô hình LMS đã được các trường học tại Mỹ áp dụng từ 10 năm nay và mang lại lợi ích cho các nhà quản lý giáo dục, các thầy cô và đặc biệt là học sinh. Trên thế giới, có khoảng 73 triệu học sinh, sinh viên đang theo học chính thức trên các nền tảng LMS tương tự như MegaSchool, trong đó 90% học sinh, sinh viên thích cách giảng dạy trên nền tảng này hơn so với cách học tập truyền thống. MegaSchool chắc chắn sẽ là một giải pháp LMS đột phá cho mục tiêu “chuyển đổi số” trong giáo dục tại Việt Nam.”</p>
                                    </div>
                                    <div className="avatar" style={{minWidth: 150, minHeight:150}}>
                                        <img src="img/Anna.jpg" className="img-responsive" alt="Bà Annabelle Vultee" />
                                    </div>
                                    <div className="author">
                                        <span>Bà Annabelle Vultee</span>
                                        <span>Thành viên Hội đồng Quản trị Tập đoàn Giáo dục EQuest;</span>
                                        <span>Nguyên Giám đốc điều hành của EF Education First tại Châu Âu, Trung Đông và Châu Phi</span>
                                        </div>
                                </div>
                                <div class="testimonial">
                                    <div class="content">
                                        <p>“Khi đưa các bài giảng, Alpha không chuyển đổi 100% các chương trình học trực tiếp sang trực tuyến, mà thay vào đó là xây dựng một khung chương trình dạy trực tuyến riêng phù hợp, kết hợp giữa tự học và học qua tương tác trực tiếp, khung thời lượng các môn học được bố trí lại khoa học, đảm bảo sức khỏe cho học sinh. Một số các bộ môn như Thể thao, Âm nhạc,... vẫn được đưa vào giảng dạy bằng các bài giảng tương tác, để có thể chăm sóc về cả đời sống tinh thần học sinh trong mùa dịch. Chương trình học tập được xây dựng uyển chuyển có thể chuyển đổi qua lại giữa trạng thái bình thường và giãn cách trong thời kỳ dịch bệnh, đảm bảo được đầu ra theo khung chương trình nhà trường được ban hành. Học online cho kết quả tốt như học trực tiếp là định hướng lâu dài của nhà trường.”</p>
                                    </div>
                                    <div class="avatar" style={{minWidth: 150, minHeight:150}}>
                                    <img src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_515,h_512/https://alphaschool.edu.vn/wp-content/uploads/2020/01/Tr%E1%BA%A7n-Th%E1%BB%8B-Thanh-Th%E1%BB%A7y.png" className="img-responsive" alt="TS. Trần Thị Thanh Thuỷ" />

                                    </div>
                                    <div class="author">
                                        <span>TS. Trần Thị Thanh Thủy</span>
                                        <span>Chủ tịch Hội đồng giáo dục,</span>
                                        <span>Hệ thống giáo dục Alpha, Hà Nội</span>
                                    </div>
                                </div>
                                <div className="testimonial">
                                    <div className="content">
                                        <p>“Trên nền tảng của MegaSchool, học sinh có thể ghi hình câu trả lời và gửi ngay lập tức cho giáo viên. Sau khi giáo viên nhận xét, phản hồi thì kết quả đánh giá sẽ đi thẳng vào hồ sơ của học sinh tạo nên một học bạ số. Hơn nữa, MegaSchool có hệ thống quản lý học tập, bọn mình có thể số hóa, lượng hóa được và tạo riêng cho mỗi bạn học sinh một lộ trình kế hoạch học tập riêng.”</p>
                                    </div>
                                    <div className="avatar" style={{minWidth: 150, minHeight:150}}>
                                        <img src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_400/https://alphaschool.edu.vn/wp-content/uploads/2021/03/Do%C3%A3n-Minh-M%C3%A3o.png" className="img-responsive" alt="Doãn Minh Mão" />
                                    </div>
                                    <div className="author">
                                        <span>ThS. Doãn Minh Mão</span>
                                        <span>Giám đốc học thuật,</span>
                                        <span>Trung tâm nghiên cứu và giảng dạy các chương trình quốc tế,</span>
                                            <span>Hệ thống giáo dục Alpha, Hà Nội </span>
                                    </div>
                                </div>
                                <div className="testimonial">
                                    <div className="content">
                                        <p>“MegaSchool không chỉ là giải pháp tình thế cho việc dạy học trực tuyến thời kỳ Covid, đây chính là áp dụng công nghệ giáo dục vào thực tế giảng dạy và học tập. MegaSchool với ứng dụng của công nghệ AI chính là giải pháp giúp mở rộng không gian học tập và giảng dạy, từ đó có những bước đột phá trong quản lý giáo dục trong thời gian tới.”</p>
                                    </div>
                                    <div className="avatar" style={{minWidth: 150, minHeight:150}}>
                                        <img src="img/th.png" className="img-responsive" alt="Nhà giáo Ưu tú Lê Tiến Thành" />
                                    </div>
                                    <div className="author">
                                        <span>Nhà giáo Ưu tú Lê Tiến Thành</span>
                                        <span>Chủ tịch Hội đồng Giáo dục, Hiệu trưởng trường Tiểu học & THCS Thực nghiệm Victory, Hà Nội;</span>
                                        <span>Nguyên Tổng Biên tập Tạp chí Giáo dục Tiểu học;</span>
                                            <span>Nguyên Vụ trưởng Vụ Giáo dục Tiểu học, Bộ GD&ĐT</span>
                                    </div>
                                </div>
                                <div className="testimonial">
                                    <div className="content">
                                        <p>“Ứng dụng công nghệ như MegaSchool là bước chuyển mình cần thiết cho nhà trường trong thời đại chuyển đổi số. Tích hợp “bài giảng số” trong giảng dạy, MegaSchool sẽ giúp các thầy cô có thêm nhiều thời gian tương tác với học sinh, ngay cả khi học trực tuyến, giúp giáo viên trở thành “người truyền cảm hứng” thực sự cho các học sinh.”</p>
                                    </div>
                                    <div className="avatar" style={{minWidth: 150, minHeight:150}}>
                                        <img src="img/chinhlt.png" className="img-responsive" alt="TS. Lê Thị Chính" />
                                    </div>
                                    <div className="author">
                                        <span>TS. Lê Thị Chính</span>
                                        <span>Chủ tịch Hội đồng Giáo dục, Hệ thống Trường Liên cấp Newton;</span>
                                        <span>Nguyên Hiệu trưởng Trường THPT Chuyên Ngoại ngữ, Đại học Ngoại ngữ, ĐHQG Hà Nội.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section galleries">
                    <header className="section-head">
                        <div className="container">
                            <div className="main_title wow fadeInDown">
                                <h2>
                                    <span>Hình ảnh của Mega<strong>School</strong></span>
                                </h2>
                            </div>
                        </div>
                    </header>
                    <div className="section-body">
                        <div className="container">
                            <div className="masonry-gallery columns-4 clearfix lightbox" data-img-big="1" data-plugin-options='{"delegate": "a", "gallery": {"enabled": true}}'>
                                <a className="image-hover gallery-item" href="img/1.jpg">
                                    <img src="img/1.jpg" alt="..."/>
                                </a>
                                <a className="image-hover gallery-item" href="img/2.jpg">
                                    <img src="img/2.jpg" alt="..."/>
                                </a>
                                <a className="image-hover gallery-item" href="img/3.jpg">
                                    <img src="img/3.jpg"  alt="..."/>
                                </a>
                                <a className="image-hover gallery-item" href="img/4.jpg">
                                    <img src="img/4.jpg"   alt="..."/>
                                </a>
                                <a className="image-hover gallery-item" href="img/5.jpg">
                                    <img src="img/5.jpg"   alt="..."/>
                                </a>
                                <a className="image-hover gallery-item" href="img/6.jpg">
                                    <img src="img/6.jpg"   alt="..."/>
                                </a>
                                <a className="image-hover gallery-item" href="img/cgd8-1-1@3x.jpg">
                                    <img src="img/cgd8-1-1@3x.jpg"   alt="..."/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section news" id="news">
                    <header className="section-head">
                        <div className="container">
                            <div className="main_title wow fadeIn">
                                <h2>Tin tức - Sự kiện</h2>
                            </div>
                        </div>
                    </header>
                    <div className="section-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className="blog-lists">
                                    <article className="blog wow fadeIn">
                                            <figure className="blog-img">
                                                <img src="img/img-post-ung-dung-hoc-lieu-so.png" alt='Học trực tuyến đang tạo cơ hội cho trẻ em thích ứng với công nghệ nhanh'/>
                                                <a href="/bai-ung-dung-hoc-lieu-so" className="blog-link"></a>
                                            </figure>
                                            <div className="blog-content">
                                                <span className="date">19 tháng 04, 2022</span>
                                                <h3>
                                                    <a href="/bai-ung-dung-hoc-lieu-so">Ứng dụng học liệu số trong giảng dạy tại trường</a>
                                                </h3>
                                                <p className="desc">Sử dụng học liệu số trong việc dạy và học đang là xu hướng được ngành giáo dục quan tâm, chú trọng.Các bài giảng, tài liệu số không chỉ dành riêng cho phương pháp học trực tuyến mà có thể sử dụng cho giảng dạy trực tiếp tại trường, giúp thầy cô tiết kiệm công sức chuẩn bị giáo án, mang đến cho học sinh tiết học sinh động, hấp dẫn, tăng tính tương tác giữa thầy và...</p>
                                            </div>
                                        </article>
                                        <article className="blog wow fadeIn">
                                            <figure className="blog-img">
                                                <img src="https://image.thanhnien.vn/w2048/Uploaded/2021/bfznsfyr-bn/2021_11_30/ismart-1-8702.jpg" className="img-responsive" alt='Học trực tuyến đang tạo cơ hội cho trẻ em thích ứng với công nghệ nhanh'/>
                                                <a href="https://thanhnien.vn/hoc-truc-tuyen-dang-tao-co-hoi-cho-tre-em-thich-ung-voi-cong-nghe-nhanh-post1406713.html" className="blog-link"></a>
                                            </figure>
                                            <div className="blog-content">
                                                <span className="date">30 tháng 11, 2021</span>
                                                <h3>
                                                    <a href="https://thanhnien.vn/hoc-truc-tuyen-dang-tao-co-hoi-cho-tre-em-thich-ung-voi-cong-nghe-nhanh-post1406713.html">Học trực tuyến đang tạo cơ hội cho trẻ em thích ứng với công nghệ nhanh</a>
                                                </h3>
                                                <p className="desc">Mặc dù còn những thách thức tuy nhiên việc học tập trực tuyến đã dần trở nên quen thuộc và các em học sinh có cơ hội thích ứng với công nghệ một cách nhanh chóng, mở thêm “cánh cửa” kho tàng kiến thức lớn trên internet…</p>
                                            </div>
                                        </article>
                                        <article className="blog wow fadeIn">
                                            <figure className="blog-img">
                                                <img src="https://scontent.fhan2-1.fna.fbcdn.net/v/t39.30808-6/255877314_294186222710723_3080783519029985270_n.jpg?_nc_cat=1&ccb=1-5&_nc_sid=730e14&_nc_ohc=HrOwo9fRehYAX-L8S3H&_nc_ht=scontent.fhan2-1.fna&oh=5b74ce83022fc7f6e1b83df55b4b545f&oe=61A5A020" className="img-responsive" alt='🎙 TỌA ĐÀM TRỰC TUYẾN "DẠY VÀ HỌC TRỰC TUYẾN - GÓC NHÌN TỪ PHỤ HUYNH VÀ HỌC SINH" 🎙'/>
                                                <a href="https://forms.gle/NLqc5vHZDcNiTv3r8" className="blog-link"></a>
                                            </figure>
                                            <div className="blog-content">
                                                <span className="date">06 tháng 11, 2021</span>
                                                <h3>
                                                    <a href="https://forms.gle/NLqc5vHZDcNiTv3r8">🎙 TỌA ĐÀM TRỰC TUYẾN "DẠY VÀ HỌC TRỰC TUYẾN - GÓC NHÌN TỪ PHỤ HUYNH VÀ HỌC SINH" 🎙</a>
                                                </h3>
                                                <p className="desc">Chưa bao giờ vấn đề học trực tuyến lại được các bậc phụ huynh quan tâm đặc biệt như hiện nay. Khác với năm học trước, việc phải học trực tuyến tại nhà trong thời gian dài khi năm học mới đã bắt đầu khiến nhiều phụ huynh bỡ ngỡ, lo lắng và khá lúng túng trước những yêu cầu về sự sẵn sàng công nghệ cũng như phương pháp định hướng, theo dõi và đồng hành giúp con tiếp thu kiến thức hiệu quả và thoải mái khi học trực tuyến.</p>
                                            </div>
                                        </article>
                                        <article className="blog wow fadeIn">
                                            <figure className="blog-img">
                                                <img src="https://nld.mediacdn.vn/thumb_w/684/291774122806476800/2021/11/6/tt-16361774354692135323695.jpeg" className="img-responsive" alt="Kiểm tra, đánh giá trực tuyến: Thực chất hay không?"/>
                                                <a href="" className="blog-link"></a>
                                            </figure>
                                            <div className="blog-content">
                                                <span className="date">06 tháng 11, 2021</span>
                                                <h3>
                                                    <a href="https://nld.com.vn/hoc-duong/kiem-tra-danh-gia-truc-tuyen-thuc-chat-hay-khong-20211106124839779.htm">Kiểm tra, đánh giá trực tuyến: Thực chất hay không?</a>
                                                </h3>
                                                <p className="desc">(NLĐO)- Kết quả kiểm tra, đánh giá trực tuyến có bảm bảo tính công bằng, chính xác và minh bạch như các kỳ thi trực tiếp là băn khoăn của nhiều chuyên gia cũng như phụ huynh, học sinh.</p>
                                            </div>
                                        </article>
                                        <article className="blog wow fadeIn">
                                            <figure className="blog-img">
                                                <img src="https://images.giaoducthoidai.vn/public/nhungnt/2021-11-06/hoi-thao-day-va-hoc-truc-tuyen-goc-nhin-cua-giao-vien-va-nha-quan-ly.jpg" className="img-responsive" alt="Để dạy học trực tuyến đạt hiệu quả như mong đợi?"/>
                                                <a href="" className="blog-link"></a>
                                            </figure>
                                            <div className="blog-content">
                                                <span className="date">06 tháng 11, 2021</span>
                                                <h3>
                                                    <a href="https://giaoducthoidai.vn/trao-doi/de-day-hoc-truc-tuyen-dat-hieu-qua-nhu-mong-doi-dMMFDrK7R.html">Để dạy học trực tuyến đạt hiệu quả như mong đợi? </a>
                                                </h3>
                                                <p className="desc">GD&TĐ - Dạy học trực tuyến hiện nay chưa tận dụng được hết ưu điểm, cũng như chưa phát huy hiệu quả như mong đợi. Cần đầu tư bài bản hơn cho dạy học online, từ cơ sở vật chất, nền tảng công nghệ, chương trình dạy học…</p>
                                            </div>
                                        </article>
                                        <article className="blog wow fadeIn">
                                            <figure className="blog-img">
                                                <img src="img/6b842ae9b6f77ea927e6.jpg" className="img-responsive" alt="📣 HỘI THẢO “DẠY VÀ HỌC TRỰC TUYẾN - GÓC NHÌN CỦA GIÁO VIÊN VÀ NHÀ QUẢN LÝ” 📣 ."/>
                                                <a href="" className="blog-link"></a>
                                            </figure>
                                            <div className="blog-content">
                                                <span className="date">05 tháng 11, 2021</span>
                                                <h3>
                                                    <a href="https://forms.gle/aXemSKkif5uToihH9">📣 HỘI THẢO “DẠY VÀ HỌC TRỰC TUYẾN - GÓC NHÌN CỦA GIÁO VIÊN VÀ NHÀ QUẢN LÝ” 📣 </a>
                                                </h3>
                                                <p className="desc">Khi chuyển đổi mô hình trường học truyền thống sang mô hình trực tuyến do diễn biến dịch bệnh, các nhà quản lý và giáo viên sẽ bị ảnh hưởng đầu tiên khi ngay lập tức phải chuyển mình sang một loại hình học tập mới trong khi hệ thống cơ sở còn vô vàn khó khăn như: hạ tầng mạng, thiết bị, phần mềm, năng lực tin học của giáo viên và học sinh vẫn còn hạn chế…</p>
                                            </div>
                                        </article>
                                        <article className="blog wow fadeIn">
                                            <figure className="blog-img">
                                                <img src="https://www.giaoduc.edu.vn/upload/images/2021/10/23/dayvahoc.jpg" className="img-responsive" alt="day va hoc truc tuyen."/>
                                                <a href="" className="blog-link"></a>
                                            </figure>
                                            <div className="blog-content">
                                                <span className="date">23 tháng 10, 2021</span>
                                                <h3>
                                                    <a href="https://www.giaoduc.edu.vn/day-va-hoc-truc-tuyen-tien-toi-truong-hoc-thong-minh-2021-2021.htm"> Dạy và học trực tuyến: Tiến tới trường học thông minh </a>
                                                </h3>
                                                <p className="desc">GS.TS Lê Anh Vinh, Phó Viện trưởng Phụ trách Viện Khoa học Giáo dục Việt Nam khẳng định, làn sóng học tập trực tuyến sẽ không mất đi ngay cả khi dịch COVID-19 được kiểm soát, ngược lại sẽ giúp cải thiện việc dạy và học trong nhà trường, tiến tới trường học thông minh.</p>
                                            </div>
                                        </article>
                                        <article className="blog wow fadeIn">
                                            <figure className="blog-img">
                                                <img src="img/webina.jpg" className="img-responsive" alt="day va hoc truc tuyen."/>
                                                <a href="" className="blog-link"></a>
                                            </figure>
                                            <div className="blog-content">
                                                <span className="date">22 tháng 10, 2021</span>
                                                <h3>
                                                    <a href="https://forms.gle/mEBeJ5ShR3sJjdj99"> 🎥 HỘI THẢO TRỰC TUYẾN ""DẠY VÀ HỌC TRỰC TUYẾN - KHÓ KHĂN THỰC TẾ VÀ GIẢI PHÁP CÔNG NGHỆ"" 🎥 </a>
                                                </h3>
                                                <p className="desc">Dịch Covid-19 đã tác động không nhỏ đến ngành Giáo dục, làm thay đổi hoạt động dạy và học của giáo viên và học sinh, sinh viên cả nước cũng như hoạt động điều hành, quản lý của các trường học, cơ sở giáo dục, dạy nghề.</p>
                                            </div>
                                        </article>
                                       <article className="blog wow fadeIn">
                                            <figure className="blog-img">
                                                <img src="img/d72eb5c59ba452fa0bb5.jpg" className="img-responsive" alt="Học sinh lớp 2 một trường thuộc hệ thống EQuest học online."/>
                                                <a href="" className="blog-link"></a>
                                            </figure>
                                            <div className="blog-content">
                                                <span className="date">29 tháng 09, 2021</span>
                                                <h3>
                                                    <a href="https://vnexpress.net/equest-cung-cap-mien-phi-giai-phap-day-va-hoc-truc-tuyen-4363363.html">EQuest cung cấp miễn phí giải pháp dạy và học trực tuyến</a>
                                                </h3>
                                                <p className="desc">Tập đoàn Giáo dục EQuest công bố sẽ cung cấp miễn phí giải pháp dạy và học trực tuyến MegaSchool cho tất cả trường phổ thông công lập năm học 2021-2022.</p>
                                            </div>
                                        </article>
                                        <article className="blog wow fadeIn">
                                            <figure className="blog-img">
                                                <img src="img/alphayoutube.png" className="img-responsive" alt=""/>
                                                <a href="" className="blog-link"></a>
                                            </figure>
                                            <div className="blog-content">
                                                <span className="date">06 tháng 07, 2021</span>
                                                <h3>
                                                    <a href="https://www.youtube.com/watch?v=4ET1IrBjU9E&t=46s">Bài giảng tương tác, nội dung sống động tăng hứng thú học tập</a>
                                                </h3>
                                                {/* <p className="desc">Hệ thống các "Bài giảng số tiếng Việt"  và những video tương tác sống động giúp học sinh hứng thú và thu nhận nhiều kiến thức hơn so với các video và cách học online truyền thống.</p> */}
                                            </div>
                                        </article>
                                        <article className="blog wow fadeIn">
                                            <figure className="blog-img">
                                                <img src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_720,h_540/https://alphaschool.edu.vn/wp-content/uploads/2021/07/206702979_4362264143805985_1954526553151865200_n.jpg" className="img-responsive" alt=""/>
                                                <a href="https://vnexpress.net/equest-cung-cap-mien-phi-giai-phap-day-va-hoc-truc-tuyen-4363363.html" className="blog-link"></a>
                                            </figure>
                                            <div className="blog-content">
                                                <span className="date">02 tháng 07, 2021</span>
                                                <h3>
                                                    <a href="https://alphaschool.edu.vn/cac-cap-do-ve-day-hoc-truc-tuyen/?fbclid=IwAR2dovFDyD4nxsWsaQMATS8J7ByymVAXqlfKiZoHxrx1mDzhH5z_YV47FNs">Cấp độ dạy học trực tuyến toàn diện</a>
                                                </h3>
                                                <p className="desc">Nền tảng Quản trị học tập (LMS) giúp hoàn thiện tổ chức mô hình trường học trực tuyến toàn diện </p>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className="video">
                                        <img src="img/video2.jpg" className="img-responsive" alt="" />
                                        <a href="https://www.youtube.com/watch?v=LB40_Be9XJ4" className="btn-play video-image popup-youtube"></a>
                                        <div className="caption">
                                            <span className="date">17 tháng 08, 2021</span>
                                            <h3>VTV1 – ALPHA SẴN SÀNG ĐÓN NĂM HỌC MỚI VỚI MÔ HÌNH TRƯỜNG HỌC TRỰC TUYẾN CẤP
                                                ĐỘ 4</h3>
                                                <p>​​Megaschool đồng hành cùng Ban giám hiệu và toàn thể cán bộ giáo viên Alpha đã làm việc không ngừng nghỉ để xây dựng nên mô hình Trường học trực tuyến cấp độ 4, sẵn sàng chào đón các em học sinh bước vào năm học mới 2021 - 2022 nhiều khó khăn nhưng cũng nhiều cơ hội.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section form-contact" id="contact">
                    <header className="section-head">
                        <div className="container">
                            <div className="main_title">
                                <h2>
                                    <span>Liên hệ với</span>
                                    <span>MegaSchool</span>
                                </h2>
                            </div>
                        </div>
                    </header>
                    <div className="section-body">
                        <div className="container">
                            <div className="form">
                                <form role="form">
                                    <legend>Thông tin giáo viên</legend>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="" value={nameTeacher}
                                                onChange={(event)=>{setNameTeacher(event.target.value)}}
                                                placeholder="Họ và tên giáo viên" />
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="" placeholder="Số điện thoại"
                                                value={phoneTeacher}
                                                onChange={(event)=>{setPhoneTeacher(event.target.value)}} />
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="" placeholder="Email"
                                                value={emailTeacher}
                                                onChange={(event=>{setEmailTeacher(event.target.value)})} />
                                            </div>
                                        </div>
                                    </div>
                                    <legend>Thông tin trường</legend>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="" placeholder="Tên trường"
                                                value={schoolName}
                                                onChange={event=>{setSchoolName(event.target.value)}}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="" placeholder="Số điện thoại"
                                                value={schoolPhone}
                                                onChange={event=>{setSchoolPhone(event.target.value)}}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="" placeholder="Email"
                                                value={schoolEmail}
                                                onChange={event=>{setSchoolEmail(event.target.value)}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="" placeholder="Nhu cầu hiện nay của đơn vị"
                                                value={content}
                                                onChange={event=>{setContent(event.target.value)}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group action">
                                                <button onClick={event=>{handel_submit_form(event)}}  className="btn btn-primary">
                                                    <span>Đăng ký ngay</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section clients">
                    <header className="section-head">
                        <div className="container">
                            <div className="main_title">
                                <h2>Đối tác</h2>
                            </div>
                        </div>
                    </header>
                    <div className="section-body">
                        <div className="container">
                            <div className="client-desktop">
                                <div className="owl_client owl-carousel owl-theme">
                                    <div className="client">
                                        <a href='https://www.kkr.com/'><img src="img/KKR.png" alt="" style={{height: 50}}/></a>
                                    </div>
                                    <div className="client">
                                        <a href='https://equest.vn/'><img src="img/EQuest.svg" alt=""   style={{height: 50}}/></a>
                                    </div>
                                    <div className="client">
                                        <a href='https://ismart.edu.vn/'><img src="img/iSM.svg" alt=""  style={{height: 50}}/></a>
                                    </div>
                                    <div className="client" >
                                        <a href='https://dreamlab.com.vn/'><img  src="img/iTD.svg" alt=""  style={{height: 50}}/></a>
                                    </div>
                                    <div className="client">
                                        <a href='https://www.789.vn/'><img  src="img/789.svg" alt=""  style={{height: 50}}/></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        <footer className="footer">
    <div className="footer-primary">
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-7 col-xs-12">
                    <div className="widget logo_footer">
                        <a href="/">
                            <img src="img/logo_footer.svg" className="img-responsive" alt=""/>
                        </a>
                    </div>
                </div>
                <div className="col-lg-5 col-md-5 col-xs-12">
                    <div className="widget office">
                        <div className="address">
                            <h4 className="text-uppercase">ĐỊA CHỈ VĂN PHÒNG</h4>
                            <p><strong>TP.HỒ CHÍ MINH</strong></p>
                            <p><span>Địa chỉ</span>Tầng 19, Phòng 1901 Saigon Trade Center – Số 37 Tôn Đức Thắng, Phường Bến Nghé, Quận 1, Thành phố Hồ Chí Minh, Việt Nam
                            </p>
                            <p><span>Hotline</span>1900 234 582</p>
                            <p><span>Email</span><a href="mailto:info@megaschool.edu.vn">info@megaschool.edu.vn</a></p>
                        </div>
                        <div className="work_at">
                            <p><strong>Giờ làm việc</strong></p>
                            <p style={{marginBottom: 0, paddingBottom:0}}>Thứ 2 - Thứ 6 </p>
                            <p style={{marginTop:0, paddingTop:0}}>08h:30 - 17h:30</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <div className="copyright">
                        <p>Copyright ©2021 All rights reserved | megaschool.edu.vn</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
        <div style={{position:"fixed",bottom:"10px",zIndex:1000}} className="fb-send-to-messenger" messenger_app_id="361260004211097" page_id="101197162009631" color="blue" size="standard" cta_text="MESSAGE_ME" data-ref="Welcome-Block"></div>

        <button className="backtop">Backtop</button>

        <NotificationContainer/>
    </div>
  );
}

export default App;
